<template>
  <div>
    <head-layout
      :head-btn-options="headBtnOptions"
      :head-title="$t('cip.dc.InterfaceSystem.title.NewInterfaceSystemConfiguration')"
      @head-save="headSave('save')"
      @head-save-back="headSave('back')"
      @head-cancel="headCancel"
    ></head-layout>
    <div class="newZtFormBox" style="background-color: white; margin-top: 10px">
      <el-form ref="ruleForm" :model="form" :rules="rules" label-width="140px">
        <!-- <form-layout ref="formLayout" :dataForm="form" :group="group"></form-layout> -->
        <el-collapse v-model="activeName">
          <el-collapse-item
            :title="$t('cip.dc.Token.field.RequestHeader')"
            name="1"
          >
            <template slot="title">
              <i
                class="avue-group__title avue-group__header"
                style="
                  color: #999999 !important;
                  font-style: normal;
                  padding: 0 10px;
                "
                ><i
                  class="el-icon-document"
                  style="font-size: 20px; padding: 0 10px"
                ></i>
                {{ $t("cip.dc.Token.field.baseInfo") }}
              </i>
            </template>
            <el-row>
              <el-col :span="8">
                <el-form-item :label='$t("cip.dc.InterfaceSystem.field.isToken2")' prop="isToken">
                  <el-radio-group v-model="form.isToken">
                    <el-radio :label="1">{{$t("cip.dc.InterfaceSystem.field.yes")}}</el-radio>
                    <el-radio :label="2">{{$t("cip.dc.InterfaceSystem.field.no")}}</el-radio>
                  </el-radio-group>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item
                  :label="$t('cip.dc.zljkcj.field.SourceSystem')"
                  prop="name"
                >
                  <!-- <el-select v-model="form.name" :placeholder="$t('cip.dc.zljkcj.field.SourceSystem')" class='new-form-input'>
                    <el-option v-for="(item, index) in idList" :key="index" :label="item.name" :value="item.name"></el-option>
                  </el-select> -->

                  <el-input
                    v-model="form.name"
                    :placeholder="$t('cip.dc.zljkcj.field.SourceSystem')"
                    autocomplete="off"
                    
                  ></el-input>
                </el-form-item>
              </el-col>

              <el-col :span="8">
                
                <el-form-item :label='$t("cip.dc.zljkcj.field.ReturnDataItem")' prop="config">
                  <el-input
                    v-model="form.config"
                    :placeholder='$t("cip.dc.zljkcj.field.ReturnDataItem")'
                    autocomplete="off"
                    
                  ></el-input>
                </el-form-item>
              </el-col>
              
            </el-row>

            <el-row>
              

              <el-col :span="8">
                
                <el-form-item :label='$t("cip.dc.FullInter.field.ReturnStatusCode")' prop="resultCode">
                  <el-input
                    v-model="form.resultCode"
                    :placeholder='$t("cip.dc.FullInter.field.ReturnStatusCode")'
                    autocomplete="off"
                    
                  ></el-input>
                </el-form-item>
              </el-col>

              <el-col :span="8">
                
                <el-form-item :label='$t("cip.dc.FullInter.field.ReturnStatusValue")' prop="result">
                  <el-input
                    v-model="form.result"
                    :placeholder='$t("cip.dc.FullInter.field.ReturnStatusValue")'
                    autocomplete="off"
                    
                  ></el-input>
                </el-form-item>
              </el-col>
            </el-row>
          </el-collapse-item>

          <el-collapse-item
          
            :title='$t("cip.dc.InterfaceSystem.field.TokenInformation")'
            name="4"
            v-show="form.isToken == 1"
          >
            <template slot="title">
              <i
                class="avue-group__title avue-group__header"
                style="
                  color: #999999 !important;
                  font-style: normal;
                  padding: 0 10px;
                "
                ><i
                  class="el-icon-document"
                  style="font-size: 20px; padding: 0 10px"
                ></i>
                {{$t("cip.dc.InterfaceSystem.field.TokenInformation")}}
              </i>
            </template>

            <el-row>
              <el-col :span="8">
                <el-form-item :label='$t("cip.dc.FullInter.field.InterfaceAddress")' prop="tokenUrl">
                  <el-input
                    v-model="form.tokenUrl"
                    :placeholder='$t("cip.dc.FullInter.field.InterfaceAddress")'
                    autocomplete="off"
                    
                  ></el-input>
                </el-form-item>
              </el-col>

              <el-col :span="8">
                <el-form-item
                  :label="$t('cip.dc.Token.field.RequestMethod')"
                  prop="requestMethod"
                >
                  <el-select
                    v-model="form.requestMethod"
                    :placeholder="$t('cip.dc.Token.field.RequestMethod')"
                    
                  >
                    <el-option :value="1" label="get"></el-option>
                    <el-option :value="2" label="post"></el-option>
                  </el-select>
                </el-form-item>
              </el-col>

              <el-col :span="8">
                <el-form-item
                  :label="$t('cip.dc.Token.field.Splicinginformation')"
                >
                  <el-input
                    v-model="form.tokenPrefix"
                    :placeholder="$t('cip.dc.Token.field.Splicinginformation')"
                    autocomplete="off"
                    
                  ></el-input>
                </el-form-item>
              </el-col>
            </el-row>

            <el-row>
              <el-col :span="8">
                
                
                <el-form-item :label="$t('cip.dc.zljkcj.field.TokenField')">
                  <el-input
                    v-model="form.tokenField"
                    :placeholder="$t('cip.dc.zljkcj.field.TokenField')"
                    autocomplete="off"
                    
                  ></el-input>
                </el-form-item>
              </el-col>
            </el-row>
          </el-collapse-item>

          <el-collapse-item
            :title="$t('cip.dc.Token.field.RequestHeader')"
            name="2"
            v-show="form.isToken == 1"
          >
            <template slot="title">
              <i
                class="avue-group__title avue-group__header"
                style="
                  color: #999999 !important;
                  font-style: normal;
                  padding: 0 10px;
                "
                ><i
                  class="el-icon-document"
                  style="font-size: 20px; padding: 0 10px"
                ></i>
                {{ $t("cip.dc.Token.field.RequestHeader") }}
              </i>
            </template>
            <el-row>
              <div style="margin-bottom: 20px; padding-left: 30px">
                <el-button
                  icon="el-icon-circle-plus-outline"
                  size="mini"
                  @click="headerListAddFn"
                  >{{ $t("cip.dc.Token.field.Addrequestheader") }}</el-button
                >
              </div>
              <!-- <el-col v-for="(item,index) in form.headerList" style="margin-bottom: 20px!important;">
                key: <el-input placeholder="key" class = 'new-form-input' v-model="item.key"  autocomplete="off"></el-input>
                value:<el-input placeholder="value" class = 'new-form-input' v-model="item.value"  autocomplete="off"></el-input>
                <el-button size = 'mini' type="danger" icon="el-icon-delete" circle @click="headerListDelFn(index)"></el-button>
              </el-col> -->

              <el-table :data="form.headerList" border style="width: 100%">
                <el-table-column label="key:" prop="key">
                  <template slot-scope="scope">
                    <el-input
                      v-model="scope.row.key"
                      autocomplete="off"
                      
                      placeholder="key"
                    ></el-input>
                  </template>
                </el-table-column>
                <el-table-column label="value:" prop="value">
                  <template slot-scope="scope">
                    <el-input
                      v-model="scope.row.value"
                      autocomplete="off"
                      
                      placeholder="key"
                    ></el-input>
                  </template>
                </el-table-column>
                <el-table-column
                  :label="this.$t('cip.cmn.btn.editBtn')"
                  prop="address"
                >
                  <template slot-scope="scope">
                    <el-button
                      circle
                      icon="el-icon-delete"
                      type="danger"
                      @click="headerListDelFn(scope.row)"
                    ></el-button>
                  </template>
                </el-table-column>
              </el-table>
            </el-row>
          </el-collapse-item>
          <el-collapse-item
            :title="$t('cip.dc.Token.field.RequestMethod')"
            name="3"
            v-show="form.isToken == 1"
          >
            <template slot="title">
              <i
                class="avue-group__title avue-group__header"
                style="
                  color: #999999 !important;
                  font-style: normal;
                  padding: 0 10px;
                "
                ><i
                  class="el-icon-document"
                  style="font-size: 20px; padding: 0 10px"
                ></i>
                
                {{ $t('cip.dc.zljkcj.field.Requestor') }}
              </i>
            </template>
            <el-row>
              <div
                style="
                  margin-bottom: 20px;
                  padding-left: 30px;
                  display: flex;
                  align-items: center;
                "
              >
                <el-button
                  icon="el-icon-circle-plus-outline"
                  size="mini"
                  @click="headerconfigListAddFn"
                  >{{$t('cip.dc.InterfaceSystem.field.AddRequestBody')}}</el-button
                >
              </div>
              <!-- <div >
                <el-row v-for="(item,index) in form.configList" >
                  <el-col :span="8">
                    key: <el-input  placeholder="key" style = 'width:250px' class = 'new-form-input' v-model="item.key"  autocomplete="off"></el-input>
                  </el-col>
                  <el-col :span="8">
                    value:<el-input  placeholder="value" style = 'width:250px' class = 'new-form-input' v-model="item.value"  autocomplete="off"></el-input>
                  </el-col>
                </el-row>
              </div> -->
              <el-table :data="form.configList" border style="width: 100%">
                <el-table-column label="key:" prop="key">
                  <template slot-scope="scope">
                    <el-input
                      v-model="scope.row.key"
                      autocomplete="off"
                      
                      placeholder="key"
                    ></el-input>
                  </template>
                </el-table-column>
                <el-table-column label="value:" prop="value">
                  <template slot-scope="scope">
                    <el-input
                      v-model="scope.row.value"
                      autocomplete="off"
                      
                      placeholder="key"
                    ></el-input>
                  </template>
                </el-table-column>
                <el-table-column
                  :label="this.$t('cip.cmn.btn.editBtn')"
                  prop="address"
                >
                  <template slot-scope="scope">
                    <el-button
                      circle
                      icon="el-icon-delete"
                      type="danger"
                      @click="configListDelFn(scope.row)"
                    ></el-button>
                  </template>
                </el-table-column>
              </el-table>
            </el-row>
          </el-collapse-item>
        </el-collapse>
      </el-form>
    </div>
  </div>
</template>

<script>
import {
  InterfaceSystemDeatil,
  tokenInitcode,
  InterfaceSystemUpdate,
} from "@/api/dataAcquisition";
import FormLayout from "@/views/components/layout/form-layout";
import { listAll, listAll2 } from "@/api/dataAcquisition/DataServices";

export default {
  name: "dataCoonfigAdd",
  computed: {},
  data() {
    return {
      idList: [],
      alist: [],
      activeName: "1",
      headBtnOptions: [
        {
          label: this.$t("cip.cmn.btn.saveBtn"),
          emit: "head-save",
          type: "button",
          icon: "",
        },
        {
          label: this.$t("cip.cmn.btn.saveBackBtn"),
          emit: "head-save-back",
          type: "button",
          icon: "",
        },
        {
          label: this.$t("cip.cmn.btn.celBtn"),
          emit: "head-cancel",
          type: "button",
          icon: "",
        },
      ],
        rules: {
          name: [
            {
              required: true,
              message: this.$t("cip.dc.zljkcj.field.SourceSystem"),
              trigger: "blur",
            },
          ],

          resultCode:[
          {
              required: true,
              message: this.$t("cip.dc.FullInter.field.ReturnStatusCode"),
              trigger: "blur",
            },
          ],
          config:[
          {
              required: true,
              message: this.$t("cip.dc.zljkcj.field.ReturnDataItem"),
              trigger: "blur",
            },
          ],
          result:[
          {
              required: true,
              message: this.$t("cip.dc.FullInter.field.ReturnStatusValue"),
              trigger: "blur",
            },
          ]
         
        },
      form: {
        name: "",
        config: "",
        resultCode: "",
        result: "",
        id: "",
        isToken: 2,
        tokenUrl: "",
        requestMethod: "",
        tokenPrefix: "",
        tokenField: "",
        headerList: [],
        configList: [],
      },
      formLabelWidth: "120px",
    };
  },
  components: {
    FormLayout,
  },
  created() {
    this.getlistAll();
    if (this.$route.query.type == "add") {
      this.getAutoCodeFn();
    }

    if (this.$route.query.type == "edit") {
      this.getInterfaceSystemDeatil();
    }
  },
  mounted() {},
  methods: {
    getlistAll() {
      
      listAll({}).then((res) => {
        this.idList = res.data.data;
      });
    },

    getInterfaceSystemDeatil() {
      InterfaceSystemDeatil({ id: this.$route.query.id }).then((res) => {
        this.form = res.data.data;
        this.form.tokenUrl = decodeURIComponent(this.form.tokenUrl);
      });
    },

    headSave(type) {
      // this.$refs.formLayout.$refs.form.validate((valid) => {
      this.$refs["ruleForm"].validate((valid) => {
        if (valid) {
          if (this.form.id) {
            if (this.form.isToken == 2) {
              this.form.tokenUrl = "";
              this.form.requestMethod = "";
              this.form.tokenPrefix = "";
              this.form.tokenField = "";
              this.form.headerList = [];
              this.form.configList = [];
            }

            this.form.tokenUrl = encodeURIComponent(this.form.tokenUrl);

            InterfaceSystemUpdate(this.form).then(() => {
              this.$message.success(this.$t("cip.dc.Token.msg"));
              this.form.tokenUrl = decodeURIComponent(this.form.tokenUrl);
              if (type == "back") {
                this.headCancel();
              }
            });
          } else {
            
            this.form.tokenUrl = encodeURIComponent(this.form.tokenUrl);
            InterfaceSystemUpdate(this.form).then(() => {
              this.$message.success(this.$t("cip.dc.Token.msg"));
              this.form.tokenUrl = decodeURIComponent(this.form.tokenUrl);
              if (type == "back") {
                this.headCancel();
              }
            });
          }
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    headCancel() {
      this.$router.$avueRouter.closeTag();
      this.$router.back();
    },
    headerListAddFn() {
      this.form.headerList.push({ key: "", value: "" });
    },
    headerListDelFn(e) {
      let index = "";
      this.form.headerList.forEach((element, idx) => {
        if (element == e) {
          index = idx;
        }
      });
      this.form.headerList.splice(index, 1);
    },
    configListDelFn(e) {
      let index = "";
      this.form.configList.forEach((element, idx) => {
        if (element == e) {
          index = idx;
        }
      });
      this.form.configList.splice(index, 1);
    },
    headerconfigListAddFn() {
      this.form.configList.push({ key: "", value: "", type: 1 });
    },
    headerconfigListDelFn(index) {
      this.form.configList.splice(index, 1);
    },

    getAutoCodeFn() {
      tokenInitcode().then((res) => {
        this.form.infCode = res.data.data;
        this.headerListAddFn();
      });
    },
  },
};
</script>

<style scoped>
/* /deep/.el-input__inner{
  width: 200px;
 } */
</style>
